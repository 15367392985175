@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body {
  padding: 0;
  margin: 0;
  overflow: hidden;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

.dashboard-app-layout {
  display: grid;
  grid-template-areas: "header header header" "sidebar content content";
  grid-template-columns: 70px 1fr;
  grid-template-rows: auto calc(100vh - 56px);
  overflow: hidden;
  height: 100vh;
}

.app-header {
  grid-area: header;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 0.3rem;
  height: 3.5rem;
}

.app-sidebar {
  grid-area: sidebar;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.app-content {
  grid-area: content;
  overflow: auto;
}

.mapboxgl-ctrl-bottom-left {
  display: none;
}
.mapboxgl-ctrl-bottom-right {
  display: none;
}

.navbar-left-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  gap: 0rem;
}
